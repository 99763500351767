import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from 'src/app/componentes/inicio/inicio.component';
import { ContactoComponent } from 'src/app/componentes/contacto/contacto.component';
import { MaquinaComponent} from 'src/app/componentes/maquina/maquina.component';
import { ServiciosComponent} from 'src/app/componentes/servicios/servicios.component';

const routes: Routes = [
  { path: '', component: InicioComponent },
  {path: 'contacto', component: ContactoComponent},
  {path: 'servicios', component: ServiciosComponent},
  {path: 'maquina/:id', component: MaquinaComponent},
  {path: 'servicios/:id', component: ServiciosComponent},
  {path: '**', component: InicioComponent}  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
