import { Component, OnInit, Input} from '@angular/core';
import { ListaMaquinas} from 'src/app/maquinaria/maquinas';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';


@Component({
  selector: 'app-maquina',
  templateUrl: './maquina.component.html',
  styleUrls: ['./maquina.component.css']
})
export class MaquinaComponent implements OnInit {
  
  maquina:any;
  otraMaquinas: any[] = [];
  urlMaquina:String;
  private sub: any;
  fotoAbierta: boolean;
  videoAbierto: boolean;
  urlFotoAbierta: String;

  width: number;

  columnaFotosPimera: any[];
  columnaFotosSegunda: any[];
  columnaFotosTercera: any[];

  slides = [];
  slideConfig = {"slidesToShow": 3, "slidesToScroll": 1};

  constructor(private route: ActivatedRoute, public router: Router) {
    this.width = window.innerWidth;
    ListaMaquinas.forEach(element => {
      this.otraMaquinas.push(element);
    });
    this.router.events.subscribe(value =>{
      this.load();
    }  
    );
  }

  load(){
    this.urlFotoAbierta = "";
    this.fotoAbierta = false;
    this.videoAbierto = false;
    this.maquina = null;
    this.sub = this.route.params.subscribe(params => {
      this.urlMaquina = params['id'];
    });
    
    ListaMaquinas.forEach(element => {
      if(element.url == this.urlMaquina){
        this.maquina = element;
        let tercio = this.maquina.fotos.length/3;
        this.columnaFotosPimera = this.maquina.fotos.slice(0,tercio);
        this.columnaFotosSegunda = this.maquina.fotos.slice(tercio,tercio*2);
        this.columnaFotosTercera = this.maquina.fotos.slice(tercio*2,this.maquina.fotos.length);
      }
    });
    
  

    if(window.innerWidth>1100 ){
      this.slideConfig = {"slidesToShow": 4, "slidesToScroll": 1};
    }else if(window.innerWidth<600 && window.innerWidth>400){
      this.slideConfig = {"slidesToShow": 2, "slidesToScroll": 1};
    }else if(window.innerWidth<400){
      this.slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};
    }
    
   
  }


  ngOnInit() {
    
  }

  

  

 

}
