import { Component } from '@angular/core';


import {trigger,state,style,animate,transition} from '@angular/animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity: 1,

      })),
      state('hide',   style({
        transform: 'translate(-50%,-60em)',
        opacity: 0
      })),
      state('showServicio', style({
        opacity: 1,
      })),
      state('hideServicio',   style({
        transform: 'translate(-50%,-60em)',
        opacity: 0
      })),
      transition('show => hide', animate('400ms ease-in')),
      transition('hide => show', animate('200ms ease-in')),
      transition('showServicio => hideServicio', animate('400ms ease-in')),
      transition('hideServicio => showServicio', animate('200ms ease-in')),
    ])
  ]
})
export class AppComponent {  
  title = 'WebGarciaMonterde';

  
  show: boolean;
  showServicio: boolean;
  presentacion: boolean;
  bloquear: boolean;

  constructor(){
    this.show = false;
    this.showServicio = false;
    this.presentacion = false;
    this.bloquear = false;


    
    setTimeout(() => {
      this.hidePresentacion();
    }, 3500);
  }

  get stateNameMaquina() {
    return this.show ? 'show' : 'hide';
  }
  get stateNameServicios() {
    return this.showServicio ? 'showServicio' : 'hideServicio';
  }
 

 

  showMaquinas(){
    this.hideServicios();
    if(!this.show){
        this.show= true;  
    }else{ 
        this.show= false; 
    }
  }

  hideMaquinas(){
    this.show = false;
  }

  showServicios(){
    this.hideMaquinas();
    if(!this.showServicio){
      this.showServicio = true;
    }else{
      this.showServicio = false;
    }
    
  }

  hideServicios(){
    this.showServicio = false;
  }

  hideMenus(){
    this.showServicio = false;
    this.show = false;
  }

  hidePresentacion(){
    this.presentacion = true;
    setTimeout(() => {
      this.bloquear = true;
    }, 850);

  }


  
  
}
