import { Component, OnInit } from '@angular/core';
import { ListaServicios} from 'src/app/maquinaria/servicios';
import {Router} from "@angular/router";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  servicio: any;
  columnaFotosPimera: any[];
  columnaFotosSegunda: any[];
  columnaFotosTercera: any[];
  private sub: any;
  fotoAbierta: boolean;
  videoAbierto: boolean;
  urlMultimedia: String;
  urlServicio:String;
  width: number;
  num: number;

  constructor(private route: ActivatedRoute, public router: Router) {
    this.width = window.innerWidth;
    this.num = 0;
    console.log(this.width)
    this.router.events.subscribe(value =>{
      this.load();
    }  );
   }

   load(){
    this.urlMultimedia = "";
    this.fotoAbierta = false;
    this.videoAbierto = false;
    this.servicio = null;
    this.sub = this.route.params.subscribe(params => {
      this.urlServicio = params['id'];
    });
    
    ListaServicios.forEach(element => {
      if(element.url == this.urlServicio){
        this.servicio = element;
        let tercio = this.servicio.fotos.length/3;
        this.columnaFotosPimera = this.servicio.fotos.slice(0,tercio);
        this.columnaFotosSegunda = this.servicio.fotos.slice(tercio,tercio*2);
        this.columnaFotosTercera = this.servicio.fotos.slice(tercio*2,this.servicio.fotos.length);
      }
    });
    
  
   
  }

  ngOnInit() {

  }


}
