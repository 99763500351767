import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  width: number;
  constructor() { 
    this.width = window.innerWidth;
  }

  ngOnInit() {

  }

}
