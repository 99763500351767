export const ListaMaquinas: any[] = [


    {
        url: 'cat973', 
        nombre: 'CATERPILLAR 973c',
        servicios: ['Limpieza de terrenos.', 'Nivelación con sistema láser.','Acondicionamiento de fincas agrícolas.','Movimientos de tierra.','Subsolado con ripper.'],
        fotos:  ['/assets/img/maquinaria/cat973_1.jpg', '/assets/img/maquinaria/cat973_9.jpg', '/assets/img/maquinaria/cat973_2.jpg', '/assets/img/maquinaria/cat973_9.jpg', '/assets/img/maquinaria/cat973_5.jpg', '/assets/img/maquinaria/cat973_4.jpg','/assets/img/maquinaria/cat973_3.jpg','/assets/img/maquinaria/cat973_6.jpg','/assets/img/maquinaria/cat973_7.jpg'],
        descripcion: 'La Caterpillar 973 es una pala de cadenas capaz de realizar una gran varidad de trabajos:'
     },
     {
        url: 'cat953', 
        nombre: 'CATERPILLAR 953c',
        servicios: ['Movimientos de tierra.', 'Carga en camiones.','Limpieza de terrenos.','Transformaciones de parcelas.','Subsolado con ripper.'],
        fotos:  ['/assets/img/maquinaria/cat953_14.jpg','/assets/img/maquinaria/cat953_8.jpg','/assets/img/maquinaria/cat953_16.jpg', '/assets/img/maquinaria/cat953_2.jpg','/assets/img/maquinaria/cat953_3.jpg','/assets/img/maquinaria/cat953_4.jpg','/assets/img/maquinaria/cat953_5.jpg','/assets/img/maquinaria/cat953_6.jpg', '/assets/img/maquinaria/cat953_7.jpg','/assets/img/maquinaria/cat953_1.jpg'],
        descripcion: 'La Caterpillar 953 es una pala de cadenas mediana. Permite realizar una gran cantidad de tareas.'
     },
     {
        url: 'casecx290', 
        nombre: 'CASE CX 290',
        servicios: ['Movimientos de tierra.','Excavación en roca con martillo hidráulico.', 'Escolleras.', 'Formación de taludes.', 'Carga de camiones',],
        fotos:  ['/assets/img/maquinaria/casecx290_1.jpg', '/assets/img/maquinaria/casecx290_5.jpg', '/assets/img/maquinaria/casecx290_6.jpg','/assets/img/maquinaria/casecx290_2.jpg','/assets/img/maquinaria/casecx290_3.jpg','/assets/img/maquinaria/casecx290_4.jpg'],
        descripcion: 'La CASE CX 290 es una excavadora de orugas de 30 toneladas. Sus  grandes dimensiones y robustez permiten trabajar en los terrenos más difíciles.'
     },
     {
        url: 'fiatkobelcoex215', 
        nombre: 'Fiat Kobelco Ex 215',
        servicios: ['Desbroces.','Construcción de escolleras.', 'Zanjados para canalizaciones.', 'Formación de taludes.', 'Derribos.','Excavación en roca con martillo hidráulico.'],
        fotos:  ['/assets/img/maquinaria/fiat-kobelco ex215_12.jpg','/assets/img/maquinaria/fiat-kobelco ex215_2.jpg','/assets/img/maquinaria/fiat-kobelco ex215_3.jpg','/assets/img/maquinaria/fiat-kobelco ex215_4.jpg','/assets/img/maquinaria/fiat-kobelco ex215_5.jpg','/assets/img/maquinaria/fiat-kobelco ex215_6.jpg','/assets/img/maquinaria/fiat-kobelco ex215_7.jpg','/assets/img/maquinaria/fiat-kobelco ex215_8.jpg','/assets/img/maquinaria/fiat-kobelco-ex215_9.jpg','/assets/img/maquinaria/fiat-kobelco ex215_10.jpg','/assets/img/maquinaria/fiat-kobelco ex215_11.jpg', '/assets/img/maquinaria/fiat-kobelco ex215_1.jpg'],
        descripcion: 'La Fiat Kobelco Ex 215 es una excavadora de orugas de 23 toneladas. Su versatilidad y robustez permiten hacen que sea una excavadora perfecta para:'
     },
     {
        url: 'gondoladaf', 
        nombre: 'DAF XF 510',
        servicios: ['Transporte de maquinaria.','Transporte de tierras.'],
        fotos:  ['/assets/img/maquinaria/gondoladaf_9.jpg', '/assets/img/maquinaria/gondoladaf_10.jpg','/assets/img/maquinaria/gondoladaf_1.jpg', '/assets/img/maquinaria/gondoladaf_6.jpg', , '/assets/img/maquinaria/gondoladaf_7.jpg','/assets/img/maquinaria/gondoladaf_4.jpg','/assets/img/maquinaria/gondoladaf_8.jpg','/assets/img/maquinaria/gondoladaf_5.jpg','/assets/img/maquinaria/gondoladaf_2.jpg','/assets/img/maquinaria/gondoladaf_3.jpg'],
        descripcion: 'Los 510 caballos de potencia de la cabeza tractora Daf XF permite el transporte de maquinaria de grandes dimensiones.'
     },
     {
        url: 'kubotau20', 
        nombre: 'Kubota U-20',
        servicios: ['Demoliciones','Zanjeos','Excavaciones en interior.'],
        fotos:  ['/assets/img/maquinaria/kubota_1.jpg','/assets/img/maquinaria/kubota_2.jpg','/assets/img/maquinaria/kubota_4.jpg','/assets/img/maquinaria/kubota_5.jpg','/assets/img/maquinaria/kubota_3.jpg'],
        descripcion: 'Mini-giratoria Kubota perfecta para trabajos en entornos reducidos. Su gran precisión permite realizar trabajos como:'
     },
     {
        url: 'liebherr904', 
        nombre: 'Liebherr 904 Litronic',
        servicios: ['Excavaciones.','Desbroces.','Limpieza de rios.','Movimientos de tierra.','Escolleras.','Zanjeos'],
        fotos:  ['/assets/img/maquinaria/liebherr904_6.jpg','/assets/img/maquinaria/liebherr904_2.jpg','/assets/img/maquinaria/liebherr904_3.jpg','/assets/img/maquinaria/liebherr904_4.jpg','/assets/img/maquinaria/liebherr904_5.jpg','/assets/img/maquinaria/liebherr904_1.jpg'],
        descripcion: 'La Liebherr 904 es una excavadora sobre ruedas de gran versatilidad.'
     },
     {
        url: 'lebrero313', 
        nombre: 'Lebrero 313',
        servicios: ['Explanaciones','Compactación de caminos'],
        fotos:  ['/assets/img/maquinaria/lebrero313_1.jpg','/assets/img/maquinaria/lebrero313_5.jpg','/assets/img/maquinaria/lebrero313_4.jpg','/assets/img/maquinaria/lebrero313_2.jpg','/assets/img/maquinaria/lebrero313_3.jpg','/assets/img/maquinaria/lebrero313_6.jpg'],
        descripcion: 'Rodillo Lebrero 313 de 14 toneladas.'
     },
     {
        url: 'yanmarvio70', 
        nombre: 'Yanmar Vio 70',
        servicios: ['Demoliciones','Zanjeos'],
        fotos:  ['/assets/img/noticias/1.jpg','/assets/img/maquinaria/yanmarvio70_5.jpg','/assets/img/maquinaria/yanmarvio70_1.jpg','/assets/img/maquinaria/yanmarvio70_6.jpg','/assets/img/maquinaria/yanmarvio70_7.jpg','/assets/img/maquinaria/yanmarvio70_2.jpg','/assets/img/maquinaria/yanmarvio70_4.jpg'],
        descripcion: 'Minigiratoria Yanmar Vio 70 perfecta para trabajos en entornos reducidos. Su gran precisión permite realizar trabajos como:'
     },
     {
        url: 'camionvolquete', 
        nombre: 'Iveco Stralis 270',
        servicios: ['Transporte de tierra.', 'Transporte de materiales de construcción.'],
        fotos:  ['/assets/img/maquinaria/ibeco_1.jpg','/assets/img/maquinaria/ibeco_2.jpg','/assets/img/maquinaria/ibeco_3.jpg'],
        descripcion: 'Camión volquete de tres ejes con pluma.'
     },
     {
      url: 'tractorfendth818vario', 
      nombre: 'Fendt 818 Vario',
      servicios: ['Transporte de tierra.', 'Transporte de materiales de construcción.'],
      fotos:  ['/assets/img/maquinaria/fentd818_3.jpg'],
      videos: [['/assets/img/maquinaria/fentd818_2.jpg', '/assets/img/maquinaria/Fendt_818_vario_1.mov'], ['/assets/img/maquinaria/fentd818_1.jpg', '/assets/img/maquinaria/Fendt_818_vario_2.mov']],
      descripcion: 'Tractor Fendt 818 Vario'
   }
  ];