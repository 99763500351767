import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SuiModule} from 'ng2-semantic-ui';
import { InicioComponent } from 'src/app/componentes/inicio/inicio.component';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { ContactoComponent } from './componentes/contacto/contacto.component';
import { MaquinaComponent } from './componentes/maquina/maquina.component';
import { ServiciosComponent } from './componentes/servicios/servicios.component';
import { SlickModule } from 'ngx-slick';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    ContactoComponent,
    MaquinaComponent,
    ServiciosComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    SuiModule,
    BrowserAnimationsModule,
    Ng2DeviceDetectorModule.forRoot(),
    SlickModule.forRoot(),
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
