export const ListaServicios: any[] = [


    {
        url: 'movimientos-de-tierra', 
        nombre: 'Movimientos de tierra y excavaciones',
        servicios: [''],
        fotos:  ['/assets/img/maquinaria/casecx290_3.jpg','/assets/img/maquinaria/cat953_16.jpg', '/assets/img/maquinaria/gondoladaf_3.jpg', '/assets/img/maquinaria/fiat-kobelco ex215_6.jpg','/assets/img/maquinaria/cat973_8.jpg','/assets/img/maquinaria/cat953_9.jpg','/assets/img/maquinaria/casecx290_1.jpg','/assets/img/maquinaria/cat953_10.jpg','/assets/img/maquinaria/cat953_11.jpg','/assets/img/maquinaria/cat953_12.jpg'],
        videos: [],
        descripcion: ''
     },
     {
        url: 'demoliciones', 
        nombre: 'Demoliciones',
        servicios: [''],
        fotos:  ['/assets/img/maquinaria/yanmarvio70_1.jpg','/assets/img/maquinaria/yanmarvio70_6.jpg','/assets/img/maquinaria/yanmarvio70_7.jpg','/assets/img/maquinaria/yanmarvio70_2.jpg','/assets/img/noticias/1.jpg','/assets/img/maquinaria/yanmarvio70_3.jpg','/assets/img/maquinaria/yanmarvio70_4.jpg','/assets/img/maquinaria/kubota_5.jpg'],
        videos: [['/assets/img/maquinaria/video3.jpg', '/assets/video/yanmar.mov']],
        descripcion: ''
     },
     {
        url: 'limpieza-terrenos', 
        nombre: 'Limpieza de terrenos y desbroces',
        servicios: ['/assets/img/maquinaria/cat953_2.jpg','/assets/img/maquinaria/fiat-kobelco ex215_11.jpg','/assets/img/maquinaria/fiat-kobelco ex215_9.JPG','/assets/img/maquinaria/fiat-kobelco ex215_8.JPG','/assets/img/maquinaria/fiat-kobelco ex215_4.jpg','/assets/img/maquinaria/fiat-kobelco ex215_5.jpg','/assets/img/maquinaria/fiat-kobelco ex215_13.jpg'],
        fotos:  ['/assets/img/maquinaria/fiat-kobelco ex215_5.jpg','/assets/img/maquinaria/fiat-kobelco ex215_8.jpg','/assets/img/maquinaria/fiat-kobelco-ex215_9.jpg','/assets/img/maquinaria/fiat-kobelco ex215_10.jpg','/assets/img/maquinaria/fiat-kobelco ex215_13.jpg'],
        videos: [['/assets/img/maquinaria/video1.jpg', '/assets/video/case.mov']],
        descripcion: ''
     },
     {
        url: 'explanaciones', 
        nombre: 'Explanaciones',
        servicios: [''],
        fotos:  ['/assets/img/maquinaria/cat973_7.JPG','/assets/img/maquinaria/cat973_2.jpg','/assets/img/noticias/3.jpg','/assets/img/maquinaria/lebrero313_5.jpg'],
        videos: [],
        descripcion: ''
     },
     {
        url: 'escolleras', 
        nombre: 'Escolleras',
        servicios: [''],
        fotos:  ['/assets/img/maquinaria/liebherr904_1.jpg','/assets/img/maquinaria/fiat-kobelco ex215_3.jpg','/assets/img/maquinaria/liebherr904_5.jpg','/assets/img/maquinaria/fiat-kobelco ex215_2.jpg'],
        video: [],
        descripcion: ''
     },
     {
        url: 'transformacionfincas', 
        nombre: 'Transformación de fincas',
        servicios: [''],
        fotos:  ['/assets/img/maquinaria/cat973_6.jpg','/assets/img/maquinaria/cat973_5.jpg','/assets/img/maquinaria/cat973_3.jpg','/assets/img/maquinaria/cat953_7.jpg','/assets/img/maquinaria/cat953_10.jpg','/assets/img/maquinaria/cat953_13.jpg'],
        videos: [['/assets/img/maquinaria/video2.jpg', '/assets/video/cat954.mov']],
        descripcion: ''
     },
     {
        url: 'arreglodecaminos', 
        nombre: 'Arreglo de caminos',
        servicios: [''],
        fotos:  ['/assets/img/maquinaria/lebrero313_6.jpg','/assets/img/maquinaria/lebrero313_5.jpg'],
        descripcion: ''
     }   
  ];
